
import {defineComponent, ref} from "vue";
import PolicyService from "@/core/services/PolicyService";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {InsuredSelect} from "@/core/composite/composite";
import CaseService from "@/core/services/CaseService";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import {IFilter} from "@/core/entity/IFilter";

export default defineComponent({
  name: "FormMoveCase",
  components: {PolicyStatus, BaseSelect, BaseForm},
  props: {
    caze: {type: Object}
  },
  emits: ['movedCase'],
  setup(props, {emit}) {
    const refCase = ref(props.caze)
    const moveCaseModel = ref<any>({policyId: '', insuredId: ''});
    const policyData = ref<any>({data: [], total: 0, pages: 0})

    const terms = ref({number: '', certificate: ''});
    const searching = ref(false);
    const submitting = ref(false);
    const policyField = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 50,
      key: {
        search: '',
        ep: '',
        s: '',
        clientId: refCase.value?.client?.id,
        number: refCase.value?.policy?.number,
        certificate: refCase.value?.policy?.certificate ? refCase.value?.policy?.certificate : ''
      },
    })

    const search = () => {
      searching.value = true
      PolicyService.findAll(policyField.value).then(res => {
        policyData.value = res;
      }).finally(() => {
        searching.value = false;
      })
    }

    const move = () => {
      submitting.value = true;
      CaseService.moveCase(refCase?.value?.id, moveCaseModel.value).then(res => {
        emit('movedCase', res);
      }).finally(() => {
        submitting.value = false
      })
    }
    return {
      policyData,
      terms,
      searching,
      moveCaseModel,
      search,
      policyField,
      ...InsuredSelect(''),
      submitting,
      move,
    }
  },
})
